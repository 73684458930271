import { Box, Grid, MenuItem, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import MainCard from "ui-component/cards/MainCard";
const status = [
  // {
  //   value: 'date',
  //   label: 'Last 7 Days',
  // },
  {
    value: "date",
    label: "This Month",
  },
  {
    value: "month",
    label: "This Year",
  },
];
const SampleResponse = () => {
  const [bankName, setBankName] = useState([]);
  const [selectedBank, setSelectedBank] = useState("WEMA");
  useEffect(() => {
    const bankName = [
      {
        label: "WEMA",
        value: "WEMA",
        data: {
        data: [
              {
                  "title": "Fund Prepaid Card",
                  "amount": 2000,
                  "type": "Default",
                  "date": "2022-09-08T15:58:55.147",
                  "narration": "Fund Prepaid Card 425405419924",
                  "status": "Successfull",
                  "creditType": "Debit",
                  "sender": "AYA PRINCE OGHENEVWEDE",
                  "senderAccountNumber": "0122926565",
                  "destinationBank": "WEMA/ALAT",
                  "destinationAccountNumber": "0254713387",
                  "recieverName": "PRINCE AYA",
                  "referenceId": "425405419924",
                  "isViewReceiptEnabled": true,
                  "tranId": " S3279409"
              },
              {
                  "title": "Goal Created:TEST",
                  "amount": 500,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal Created:TEST",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "      M41"
              },
              {
                  "title": "Goal topup:TEST",
                  "amount": 3,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal topup:TEST",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "      M43"
              },
              {
                  "title": "Goal Created:FLEXI",
                  "amount": 1000,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal Created:FLEXI",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "      M45"
              },
              {
                  "title": "Goal Created:SPEND AND SAVE",
                  "amount": 2000,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "Goal Created:SPEND AND SAVE",
                  "status": "Default",
                  "creditType": "Debit",
                  "referenceId": "",
                  "isViewReceiptEnabled": false,
                  "tranId": "   M12256"
              },
              {
                  "title": "ALAT TRANSFER FROM MICHEAL OLAGBEM MICHEAL OLAGBE",
                  "amount": 50000,
                  "type": "Default",
                  "date": "2022-09-07T00:00:00",
                  "narration": "ALAT TRANSFER FROM MICHEAL OLAGBEM MICHEAL OLAGBE",
                  "status": "Default",
                  "creditType": "Credit",
                  "referenceId": "335977883501",
                  "isViewReceiptEnabled": false,
                  "tranId": " S3279710"
              }
          ],
          "status": true,
          "balance" : 50000
        },
      },
      {
        label: "Providus",
        value: "Providus",
        data: {
          responseCode: "00",
          responseDescription: "Generation successful",
          oTransList: [
            {
              valDate: "2024/Mar/05",
              intDate: "2024/Mar/05",
              traAmt: "12",
              curCode: "NGN",
              remarks: "Statement Generation Charge for 5402085635 by e-doc",
              drCrDesc: "D",
              balAfter: "7283.64",
              docNum: "0",
              traDate: "2024/Mar/05",
              explEng: "COLLECTION",
            },
          ],
        },
      },
      {
        label: "Union",
        value: "Union",
        data: {
          " code ": " 00 ",
          " message ": " Successful ",
          " reference ": " ",
          " guid ": null,
          " items ": [
            {
              " trnDate ": 1692486000000,
              " postDate ": 1692486000000,
              " valueDate ": -61421590800000,
              " narration ": " Opening Balance ",
              " reference ": null,
              " ccy ": null,
              " drcrFlag ": " C ",
              " crAmount ": 10299.92,
              " drAmount ": null,
              " runningBalance ": 10299.92,
            },
          ],
        },
      },
      {
        label: "Heritage Bank",
        value: "HeritageBank",
        data: {
          responseCode: "000",
          responseMessage: "Account Statement Inquiry Successful",
          transactionDetail: [
            {
              sn: "1",
              transactionId: "     S000",
              transactionDate: "11/7/2023 12:00:00 AM",
              valueDate: "11/7/2023 12:00:00 AM",
              debitAmount: "0",
              creditAmount: "0",
              narration: "Opening Balance",
              narration2: "------",
              remarks: "------",
              balance: "0",
            },
          ],
        },
      },
      {
        label: "Polaris",
        value: "Polaris",
        data: {
          status: "Successful",
          message: "Transaction processed successfully",
          data: {
            provider_response_code: "00",
            provider: "Polaris",
            errors: null,
            error: null,
            provider_response: {
              account_number: "98762198723",
              closing_balance: 1056109,
              opening_balance: 186279,
              statement_list: [
                {
                  transaction_reference: "136FTIB200493D",
                  transaction_amount: 3000563,
                  balance: 1056109,
                  transaction_type: "D",
                  transaction_date: "2020-02-14T00:00:00",
                  description:
                    "MOB.BNKG:011/JOHN DOE JANE /TRANSFER 30000 FROM 98762***243 TO 2077***364:000008200214190749669842598346",
                },
              ],
            },
          },
        },
      },
      {
        label: "Sterling",
        value: "Sterling",
        data: {
          message: "00",
          response: "00",
          Responsedata: null,
          data: {
            status: "Success",
            Statements: [
              {
                status: null,
                TransactionDate: "30 MAY 19",
                TransactionAmount: "3,000.00",
                Remark:
                  "NIP/000001190529080452938582272341/ NG0020006/PRECIOUS CHUKWUEKU CHUKWU MA/000013/ZUOBAI B KENNETH/Online t o GTBank Plc ZUOBAI B KENNETH",
                TransactionId: "FT1915055LC9-SBN         ",
                AccountNo: "0066501917",
                Description: null,
                TransactionCurrency: null,
                DbCrIndication: "Dr",
              },
            ],
          },
        },
      },
      {
        label: "KeyStone",
        value: "KeyStone",
        data: {
          codes: 200,
          data: {
            data: [
              {
                transactionDate: "23-Aug-2023",
                valueDate: "23-Aug-2023",
                description:
                  "MTN Airtime top up 2347037606660  Txn Amount - NGN1000.00",
                referenceNumber: "FT23235146369421",
                amount: -1000,
              },
            ],
            message: "Successful",
            succeeded: true,
            statucCode: 200,
          },
          message: "",
        },
      },
      {
        label: "FirstBank",
        value: "FirstBank",
        data: {
          responseCode: "00",
          responseMessage: "Successful",
          requestId: "E-Doc20231106030817",
          statementPeriod: "06-Aug-2023 To 06-Nov-2023",
          startDate: "2023-08-06T00:00:00",
          endDate: "2023-11-06T00:00:00",
          transactions: [
            {
              serialNo: 1,
              tranId: "S94797607",
              partTranType: "D",
              tranAmount: "6.98",
              tranParticular:
                "FDT:RUSSD_SESS_CHG:SchdulePayment2348068254493/8/3 Ref000017349555",
              tranParticular2:
                "FPX:17349555/FDT:RUSSD_SESS_CHG:SchdulePay/0000000",
              instrumentNumber: null,
              tranCurrencyCode: "NGN",
              referenceCurrencyCode: "NGN",
              tranType: "T",
              tranSubType: "BI",
              tranRemarks: "000017349555",
              deposit: "0.0",
              withdrawal: "6.98",
              runningBalance: "1812.84",
              totalCredit: "0.0",
              totalDebit: "6.98",
              tranDate: "30-Aug-2023",
              valueDate: "30-Aug-2023",
              postedDate: "2023-08-30T16:27:30Z",
              marker: "20230830162759250",
              tranShortCode: "TRF",
              entryDate: null,
              referenceNumber: null,
            },
          ],
          customerId: "106041738",
          accountNumber: "2009736647",
          accountName: "SHAFE OLAWUMI OMOYEMI",
          address: "BLK. 5 FLAT 6, ABESAN ESTATE IPAJA LAGOS,,",
          openingBalance: "1819.82",
          closingBalance: "1777.94",
          availableBalance: "3908098.38",
          clearedBalance: "3908098.38",
          unclearedBalance: "0.0",
          lienAmount: "0.0",
          totalCredit: "0.0",
          totalDebit: "41.88",
          email: "eloho.a.mukoro@firstbanknigeria.com",
          currency: "NGN",
          productCode: "CA201",
          productName: "CURRENT A/C - PERSONAL",
          mobileNo: "2348068254493",
          branchCode: "106",
          city: "LAGOS",
          state: "LAGOS",
          country: "NIGERIA",
          acid: "41.88",
        },
      },
      {
        label: "FCMB",
        value: "FCMB",
        data: {
          data: {
            customerDetails: {
              customerID: "010766395",
              acid: "0113926018",
              foracid: "2002052442",
              customerName: "E-DOC ONLINE NIGERIA LIMITED",
              address: "13, OLANREWAJU STREET, IKEJA, LAGOS,,",
              clearedAmount: 1798.19,
              unclearedAmount: 0,
              systemReservedAmount: 0,
              lienAmount: 0,
              sanctionLimit: 0,
              emailID: "TUNDE.OGUNDIPE@E-DOCONLINE.CO.UK",
              currency: "NGN",
              openingBalance: 1798.19,
              effectiveBalance: 0,
              accountType: "CORPORATE CURRENT ACCOUNT",
              schemeCode: "OD206",
              availableBalance: 1798.19,
              drawingPower: 0,
              mobileNo: "09095990111",
              branchCode: "132",
              totalCredit: 10000,
              totalDebit: 8201.81,
              tranTime: "10/27/2023 14:09:11",
            },
            statementDetails: [
              {
                tranID: " 01342043",
                accountName: "NEW ACCOUNT/NON- ACCOUNT HOLDER'S A/C",
                customerCode: null,
                tranDate: "2023-09-25T00:00:00",
                partTranType: "C",
                tranAmount: 10000,
                tranParticular: "CDP BY E-DOC ONLINE LIMITED",
                valueDate: "2023-09-25T00:00:00",
                reference: null,
                tranShortCodeDesc: "TRANSFER",
                runningBalance: 11798.19,
                withdrawls: 0,
                deposit: 10000,
                tranDateString: "09/25/2023 ",
                valueDateString: "09/25/2023 ",
                hasTransaction: "Y",
                statementRequester: "",
                statementPeriod: "06-May-2023 To 06-Feb-2024",
                maskedAccountNumber: "******2442",
                tranSerialNo: "   2",
                tranShortCode: "TRF",
                tranType: "T",
                tranSubType: "BI",
                tranTime: "25/09/2023 20:23:59",
              },
            ],
          },
          requestId: "r1707222146518",
          code: "00",
          description: "Success",
        },
      },
      {
        label: "Access Bank",
        value: "AccessBank",
        data: {
          message: "Request successful.",
          code: "000",
          success: true,
          data: [
            {
              "accountNumber": 120200123123,
              "accountType": "Savings",
              "customerFirstName": "John",
              "customerLastName": "Doe",
              "email": "pokedex2k17@gmail.com",
              "balance": {
                  "currency": "NGN",
                  "amount": 5000
              },
              "businessPartner": {
                  "id": 344,
                  "products": [
                      {
                          "productCode": "affordability_full"
                      }
                  ]
              },
              "transactions": [
                  {
                      "transactionId": "S1000345",
                      "transactionDate": "2023-06-01T10:30:10",
                      "transactoinInformation": "SMS Alert Charges for the Month of SEPTEMBER",
                      "status": "Completed",
                      "creditType": "Credit",
                      "referenceId": "332550537787",
                      "sourceTransaction": "McDonalds",
                      "amount": {
                          "currency": "NGN",
                          "amount": 100
                      }
                  },
                  {
                      "transactionId": "S1000356",
                      "transactionDate": "2023-06-02T10:30:10",
                      "transactoinInformation": "SMS Alert Charges for the Month of SEPTEMBER",
                      "status": "Pending",
                      "creditType": "Debit",
                      "referenceId": "2039BF65",
                      "sourceTransaction": "McDonalds",
                      "amount": {
                          "currency": "NGN",
                          "amount": 100
                      }
                  }
              ]
          }
          ],
        },
      },
      {
        label: "m36",
        value: "m36",
        data: {
          message: "Request successful.",
          code: "000",
          success: true,
          data: [
            {
              transactionList: [
                {
                  type: "Treasury Bills",
                  transactionDirection: "DEBIT",
                  amount: 14717.44,
                  category: null,
                  status: true,
                  surcharge: null,
                  beneficiaryName: "M36",
                  bankName: null,
                  referenceNumber: "M36-TBILL-1704709550464",
                  paymentReference: null,
                  narration:
                    "INVESTMENT/14717.437499999999994348750000/3397d486-5da6-4739-81f7-062439c7bd46",
                  comment: null,
                  balance: null,
                  initialBalance: null,
                  creditAccountName: null,
                  creditAccountNumber: null,
                  debitAccountName: null,
                  debitAccountNumber: null,
                  currency: "NGN",
                  date: "2024-01-08T00:00:00.000+00:00",
                  createdAt: "2024-01-08T10:25:50.977+00:00",
                  updatedAt: null,
                },
              ],
              currency: "NGN",
            },
          ],
        },
      },
    ];
    setBankName(bankName);
  }, []);
  return (
    <>
      <Stack direction="row" justifyContent={"space-between"}>
        <Box />
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          gap={2}
        >
          <Grid item display={"flex"} justifyContent={"end"}>
            <TextField
              id="standard-select-currency"
              select
              value={selectedBank}
              onChange={(e) => {
                setSelectedBank(e.target.value);
              }}
            >
              {bankName.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <MainCard>
              <ReactJson
                name={"response"}
                displayDataTypes={false}
                displayObjectSize={false}
                onEdit={false}
                collapsed={false}
                onDelete={false}
                enableClipboard={false}
                src={bankName.find((item) => item.value === selectedBank)?.data}
              />
            </MainCard>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default SampleResponse;
